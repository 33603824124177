@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
table {
  border-collapse: collapse;
}
.flatpickr-input {
  width: 100%;
  height: 50px;
  outline: none;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #545964;
  font-size: 14px;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-modal {
  position: relative;
}

.custom-modal::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: -1;
}

.ant-menu-horizontal {
  flex-grow: 1;
  min-width: 0;
  overflow: visible !important;
}

.ant-menu-item {
  white-space: nowrap;
}
